import { saveAs } from 'file-saver';

export const downloadFile = (url, fileName) => {
    const options = {
        method: 'GET',
    };

    fetch(url, options)
        .then((fileResponse) => fileResponse.blob())
        .then((file) => saveAs(file, fileName))
        .catch((e) => console.error(e));
};
