import React from "react";
import styled from 'styled-components'
import CustomMedia from "../../helpers/media";

const MainWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 30px;
  ${CustomMedia.lessThan('mobile')`
    margin-bottom: 25px;
    padding: 0 20px;
  `}
`

const LeftBlock = styled.div`
  max-width: 610px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #fff;
  ${CustomMedia.lessThan('mobile')`
    display: none;
  `}
`

const RightBlock = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  text-align: right;
  max-width: 220px;
  color: #fff;
  ${CustomMedia.lessThan('mobile')`
    display: none;
  `}
`

const LeftBlockMobile = styled.div`
  font-weight: 400;
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  max-width: 220px;
  ${CustomMedia.greaterThan('mobile')`
    display: none;
  `}
`

const RightBlockMobile = styled.div`
  font-weight: 900;
  text-transform: uppercase;
  text-align: right;
  color: #fff;
  font-size: 10px;
  line-height: 14px;
  max-width: 95px;
  ${CustomMedia.greaterThan('mobile')`
    display: none;
  `}
`

const MainOneScreenBlock = () => {
    return (
        <MainWrapper>
            <LeftBlock>
                Получите список из 15 активов по металлам, энергоресурсам и товарам сельского хозяйства + подробную
                инструкцию <br/> по инвестированию
            </LeftBlock>
            <RightBlock>
                цены на металлы, энергоресурсы <br/> и продукты каждый год растут!
            </RightBlock>
            <LeftBlockMobile>
                Получите список из 15 активов <br /> по металлам, энергоресурсам<br /> и товарам сельского хозяйства <br/> + подробную
                инструкцию <br/> по инвестированию
            </LeftBlockMobile>
            <RightBlockMobile>
                цены<br/> на металлы, энергоресурсы <br/> и продукты каждый год растут!
            </RightBlockMobile>
        </MainWrapper>
    )
}

export default MainOneScreenBlock
