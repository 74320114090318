import React, {useEffect, useState} from "react";
import './assets/style/reset.css'
import './assets/style/fonts.css'
import Layout from "./hoc/layout";
import styled from "styled-components";
import Header from "./blocks/header";
import MainOneScreenBlock from "./blocks/mainOneScreenBlock";
import bg from './assets/images/Bg.png'
import {Form} from "./blocks/form";
import Policy from "./blocks/policy";
import CustomMedia from "./helpers/media";
import {MobileForm} from "./blocks/mobileForm";
import './assets/scripts/jqueryTimer'
import ym, {YMInitializer} from "react-yandex-metrika";

const Wrapper = styled.div`
  width: 100vw;
  min-width: 100vw;
  max-height: 100vh;
  height: 100vh;
  background-size: cover !important;
  background: url(${bg}) no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  ${CustomMedia.lessThan('mobile')`
    display: block;
  `}
`

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
`

const FormBlock = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  padding: 25px 30px;
  ${CustomMedia.lessThan('mobile')`
    padding: 20px;
    min-height: 150px;
  `}
`

const FormTitle = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: #282828;
  ${CustomMedia.lessThan('mobile')`
    font-size: 14px;
    line-height: 18px;
  `}
`

const PolicyTitle = styled.div`
  font-weight: 300;
  font-size: 9px;
  line-height: 11px;
  color: rgba(40, 40, 40, 0.7);
  ${CustomMedia.lessThan('mobile')`
    font-size: 8px;
    line-height: 10px;
  `}
`

function App() {
    const [isOpen, setIsOpen] = useState(false)

    const METRIKA_YM_ID = 89702438;

    useEffect(() => {
        // YM send hit (page visited)
        ym('hit', '/');
    });

    const ymParams = {
      accounts: [METRIKA_YM_ID],
      options: {
          defer: true, // for use in SPA
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
      },
      version: '2', // for YM webvisor 2.0
  };

    return (
        <Wrapper>
            <YMInitializer {...ymParams} />

            <Layout>
                <MainWrapper>
                    <Header isOpen={isOpen} showHidePolicy={() => setIsOpen(!isOpen)}/>
                    <MainOneScreenBlock/>
                    <FormBlock>
                        <FormTitle>Получить список активов и подробную инструкцию на почту</FormTitle>
                        <Form/>
                        <MobileForm/>
                        <PolicyTitle>Информация, представленная на сайте, не является основанием для принятия
                            инвестиционных решений и дана исключительно в ознакомительных целях. Деятельность
                            организации строго ограничивается обучением, консультациями
                            и помощью в заключении партнёрства с различными компаниями, а также оказанием информационной
                            и аналитической поддержки. ИП&nbsp;Кржижевская&nbsp;Лариса&nbsp;Александровна, ОГРНИП&nbsp;310774625701119 ИННИП&nbsp;773134076804
                            (тел. +7 (495) 665-93-13) выступает
                            исключительно в качестве финансового консультанта. ИП Кржижевская Лариса Александровна собирает, анализирует и
                            систематизирует информацию о поведении цен на различные финансовые активы и нематериальные
                            активы, по поручению своих клиентов.</PolicyTitle>
                    </FormBlock>
                </MainWrapper>
            </Layout>

            {isOpen && <Policy callback={() => setIsOpen(false)}/>}
        </Wrapper>
    );
}

export default App;
