import React from 'react'
import styled from 'styled-components'
import CustomMedia from "../../helpers/media";

const Wrapper = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
  ${CustomMedia.lessThan('mobile')`
    flex-direction: column-reverse;
    justify-content: flex-start;
    margin-bottom: 21px;
    padding: 0 20px;
  `}
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  opacity: ${props => props.display ? '0' : '1'};
  ${CustomMedia.lessThan('mobile')`
    width: 100%;
  `}
`

const LogoTitle = styled.p`
  color: ${props => props.color};
  font-size: 60px;
  line-height: 64px;
  font-weight: 900;
  max-width: 755px;
  ${CustomMedia.lessThan('mobile')`
    font-size: 26px;
    line-height: 28px;
  `}
`

const ButtonToPolicy = styled.div`
  cursor: pointer;
  ${CustomMedia.lessThan('mobile')`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  `}
`

const PolicyText = styled.p`
  color: #f9ffb3;
  font-weight: 400;
  max-width: 111px;
  text-align: right;
  font-size: 10px;
  line-height: 13px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: rgba(249, 255, 179, 0.6);
  }

  ${CustomMedia.lessThan('mobile')`
    font-size: 8.5px;
    line-height: 11px;
    max-width: 100px;
  `}
`

export default function Header({showHidePolicy}) {
    return (
        <Wrapper>
            <LogoWrapper>
                <LogoTitle color='#fff'>Какими товарами можно торговать на бирже и зарабатывать от 12-23% в месяц?</LogoTitle>
            </LogoWrapper>

            <ButtonToPolicy onClick={showHidePolicy}>
                <PolicyText>Политика обработки персональных данных</PolicyText>
            </ButtonToPolicy>
        </Wrapper>
    )
}
