import React from 'react'
import styled from 'styled-components'
import CustomMedia from "../../helpers/media";
import bg from '../../assets/images/Bg.png'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: auto;
  padding: 40px 50px;
  margin: auto;
  position: relative;
  z-index: 1;
  ${CustomMedia.lessThan('1778px')`
    overflow: hidden;
  `}
  ${CustomMedia.lessThan('mobile')`
    overflow: hidden;
    padding: 13px 0 0;
  `}
`

export default function Layout ({children}) {
    return(
        <Wrapper>
            {children}
        </Wrapper>
    )
}
