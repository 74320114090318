import React, {useState} from 'react'
import styled from 'styled-components'
import {useFormik} from 'formik'
import {InputComponent} from "../../components/input";
import axios from "axios";
import {ProductsFormStatus} from "../../components/formStatus";
import ym from "react-yandex-metrika";
import CustomMedia from "../../helpers/media";
import {downloadFile} from "../../helpers/utils";
import { emailRegexp } from '../../helpers/regex';
import { downloadFileName } from '../../helpers/config';

const FormBlockWrap = styled.div`
  position: relative;
  margin: 15px 0 25px;
  width: 100%;
  max-width: 930px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const FormBox = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const FormFields = styled.div`
  display: flex;
  //width: 100%;
  width: 1040px;
`
const ButtonForSend = styled.button`
  border: none;
  outline: none;
  border-radius: 10px;
  background: #f9ffb3;
  width: 280px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  margin: 0 0 0 10px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: #282828;

  &:hover {
    background-color: #f5ff80;
  }

  &[disabled] {
    cursor: initial;
    pointer-events: none;
    color: #aaa;
  }
`
const MainWrapper = styled.div`
  display: block;
  ${CustomMedia.lessThan('mobile')`
  display: none;
`}
`

export const Form = () => {
    const [formStatus, setFormStatus] = useState({success: '', error: ''})

    const initialValues = {
        name: '',
        phone: '',
        email: '', // необязательное поле
    };

    const onSubmit = (values, { resetForm }) => {
        axios.post('/send', { ...values })
            .then(function () {
                ym('reachGoal', 'zayavka');
                setFormStatus({...formStatus, success: <>Ваша заявка принята</>});
                downloadFile(`/download/${downloadFileName}`, downloadFileName);
                resetForm();
                formik.setSubmitting(false);
            })
            .catch(function (error) {
                console.error(error);
                setFormStatus({...formStatus, error: <>Произошла техническая ошибка!<br/> Попробуйте снова.</>});
                formik.setSubmitting(false);
            });
    }

    const validate = (values) => {
        let errors = {};
    
        // name
        if (!values.name) {
            errors.name = 'Обязательное поле';
        }
    
        // phone
        if (!values.phone) {
            errors.phone = 'Обязательное поле';
        }
        if (values.phone && values.phone.replace(/\D/g, '').length < 11) {
            errors.phone = 'Некорректный номер'
        }
    
          // email
        if (values.email && !emailRegexp.test(values.email)) {
            errors.email = 'Неверный формат почты'
        }
    
        return errors;
    }
    
    const formik = useFormik({
        initialValues,
        onSubmit,
        validate,
    })

    const closeFormHandle = () => {
        setFormStatus({})
    }

    const disabled = formik.isSubmitting || 
        !formik.isValid || 
        (!formik.values.name && !formik.values.phone);

    return (
        <>
        <MainWrapper>
            <FormBlockWrap>
                <FormBox onSubmit={formik.handleSubmit} noValidate>
                    <FormFields>
                        <InputComponent
                            inputMargin='0 10px 0 0'
                            inputType='name'
                            placeholder='Имя'
                            inputWidth='200px'
                            notFullWidth
                            name='name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            inputerror={formik.errors.name}
                        />
                        <InputComponent
                            inputMargin='0 10px 0 0'
                            name='phone'
                            inputType='phone'
                            placeholder='Телефон'
                            notFullWidth
                            inputWidth='230px'
                            isMask={true}
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            inputerror={formik.errors.phone}
                        />
                        <InputComponent
                            inputMargin="0"
                            inputType="email"
                            inputWidth='300px'
                            notFullWidth
                            placeholder="E-mail"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            inputerror={formik.errors.email}
                        />
                        <ButtonForSend type="submit" disabled={disabled}>Получить материалы</ButtonForSend>
                    </FormFields>
                </FormBox>
            </FormBlockWrap>
        </MainWrapper>

        {formStatus.success || formStatus.error
            ? <ProductsFormStatus closeForm={closeFormHandle} status={formStatus} getBackToForm={() => setFormStatus({})} autoWidth/>
            : null
        }
        </>
    )
}
