import jQuery from 'jquery'
(function (e) {
    var c = 0, d = {}, a = 0, b = {
        init: function (f) {
            return this.each(function () {
                d = jQuery.extend({
                    achieveTime: 60,
                    loop: 0,
                    eventList: "touchmove blur focus focusin focusout load resize scroll unload click dblclick mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave change select submit keydown keypress keyup error",
                    testPeriod: 10,
                    useMultiMode: 1,
                    callBack: function (g) {
                        console.log("Achieved!")
                    },
                    watchEvery: 1,
                    counter: {test: 0, achiev: 0}
                }, f);
                d.watchEvery *= 1000;
                if (d.useMultiMode) {
                    b.loadMultiData()
                }
                if (d.counter.achiev != -1) {
                    e(this).bind(d.eventList, b.eventTrigger);
                    b.process()
                }
            })
        }, process: function () {
            d.counter.test += 1;
            if (d.counter.test == d.testPeriod) {
                if (a) {
                    a = 0;
                    d.counter.achiev += d.testPeriod
                }
                d.counter.test = 0
            }
            c = setTimeout(b.process, d.watchEvery);
            if (d.counter.achiev >= d.achieveTime) {
                if (!d.loop) {
                    clearTimeout(c)
                }
                d.counter.achiev = d.loop ? 0 : -1;
                d.callBack.call(this, d)
            }
            if (d.useMultiMode) {
                document.cookie = "activity=" + d.counter.test + "|" + d.counter.achiev + "; path=/;"
            }
        }, eventTrigger: function () {
            a = 1
        }, loadMultiData: function () {
            var h = " activity=";
            var g = " " + document.cookie;
            if (g.length > 0) {
                if (g.indexOf(h) != -1) {
                    const offset = g.indexOf(h) + h.length;
                    var f = unescape(g.substring(offset, g.indexOf(";", offset) == -1 ? g.length : g.indexOf(";", offset))).split("|");
                    d.counter.test = parseInt(f[0]);
                    d.counter.achiev = parseInt(f[1]);
                    return;
                }
            }
            d.counter.test = d.counter.achiev = 0
        }
    };
    e.fn.activity = function (f) {
        if (b[f]) {
            return b[f].apply(this, Array.prototype.slice.call(arguments, 1))
        } else {
            if (typeof f === "object" || !f) {
                return b.init.apply(this, arguments)
            } else {
                e.error("Method " + f + " does not exist on jQuery.activity")
            }
        }
    }
})(jQuery);
