import React from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask';
import CustomMedia from "../../helpers/media";

const InputElement = styled.p`
  color: ${props => props.inputerror ? '#282828' : '#282828'} !important;
  background-color: ${props => props.inputerror ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0)'} !important;
  border: 1px solid ${props => props.inputerror ? 'rgba(221, 19, 19, 0.5)' : 'rgba(40, 40, 40, 0.2)'};
  padding: 17px 20px;
  width: ${props => props.width ? props.width : '570px'};
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  border-radius: 10px;

  ${CustomMedia.lessThan('mobile')`
    width: ${props => props.width};
    padding: 11px 15px;
  `}
  ::-webkit-input-placeholder {
    color: rgba(40, 40, 40, 0.5);
    font-weight: 700;
  }

  ::-moz-placeholder {
    color: rgba(40, 40, 40, 0.5);
    opacity: 1;
    font-weight: 700;
  }

  :-ms-input-placeholder {
    color: rgba(40, 40, 40, 0.5);
    opacity: 1;
    font-weight: 700;
  }

  &:focus {
    border: 1px solid rgba(40, 40, 40, 0.2);
    background-color: rgba(0, 0, 0, 0) !important;
  }
`
const MaskInputElement = styled(InputMask)`
  color: ${props => props.inputerror ? '#282828' : '#282828'} !important;
  background-color: ${props => props.inputerror ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0)'} !important;
  border: 1px solid ${props => props.inputerror ? 'rgba(221, 19, 19, 0.5)' : 'rgba(40, 40, 40, 0.2)'};
  padding: 17px 20px;
  width: ${props => props.width ? props.width : '570px'};
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  border-radius: 10px;

  ${CustomMedia.lessThan('mobile')`
    width: ${props => props.width};
    padding: 11px 15px;
  `}
  ::-webkit-input-placeholder {
    color: rgba(40, 40, 40, 0.5);
    font-weight: 700;
  }

  ::-moz-placeholder {
    color: rgba(40, 40, 40, 0.5);
    opacity: 1;
    font-weight: 700;
  }

  :-ms-input-placeholder {
    color: rgba(40, 40, 40, 0.5);
    opacity: 1;
    font-weight: 700;
  }

  &:focus {
    border: 1px solid rgba(40, 40, 40, 0.2);
    background-color: rgba(0, 0, 0, 0) !important;
  }
`

export const Input = ({
  inputType,
  placeholder,
  value,
  onChange,
  name,
  isMask,
  width,
  inputerror
}) => {
  const setTypeForInput = () => {
    if (inputType === 'name') {
        return 'text'
    }
    if(inputType === 'email') {
        return 'email'
    }
  }

    return (
        <>
            {isMask
                ? <MaskInputElement
                    width={width}
                    value={value}
                    onChange={onChange}
                    name={name}
                    type={setTypeForInput() || 'text'}
                    placeholder={'Телефон'}
                    inputMode={'numeric'}
                    mask="+7 (999) 999-99-99"
                    inputerror={inputerror}
                />
                : <InputElement
                    as='input'
                    value={value}
                    width={width}
                    onChange={onChange}
                    name={name}
                    type={setTypeForInput() || 'number'}
                    placeholder={placeholder}
                    inputerror={inputerror}
                />}
        </>
    )
}
