import React, {useState} from 'react'
import styled from 'styled-components'
import {useFormik} from 'formik'
import {InputComponent} from "../../components/input";
import axios from "axios";
import {ProductsFormStatus} from "../../components/formStatus";
import ym from "react-yandex-metrika";
import CustomMedia from "../../helpers/media";
import {downloadFile} from "../../helpers/utils";
import { emailRegexp } from '../../helpers/regex';
import { downloadFileName } from '../../helpers/config';

const FormBlockWrap = styled.div`
  margin: 15px 0 25px;
  width: 100%;
  max-width: 1199px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const FormBox = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const FormFields = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`

const ButtonForSend = styled.button`
  border: none;
  outline: none;
  border-radius: 10px;
  background: #f9ffb3;
  width: 335px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: #282828;

  &:hover {
    background-color: #f5ff80;
  }

  &[disabled] {
    cursor: initial;
    pointer-events: none;
    color: #aaa;
  }
`

const MainWrapper = styled.div`
  display: none;
  ${CustomMedia.lessThan('mobile')`
    display: block;
  `}
`

const WrapForPhoneAndName = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const MobileForm = () => {
    const [formStatus, setFormStatus] = useState({success: '', error: ''})

    const initialValues = {
        name: '',
        phone: '',
        email: '', // необязательное поле
    };

    const onSubmit = (values, { resetForm }) => {
        axios.post('/send', { ...values })
            .then(function () {
                ym('reachGoal', 'zayavka');
                setFormStatus({...formStatus, success: <>Ваша заявка принята</>});
                downloadFile(`/download/${downloadFileName}`, downloadFileName);
                resetForm();
                formik.setSubmitting(false);
            })
            .catch(function (error) {
                console.error(error);
                setFormStatus({...formStatus, error: <>Произошла техническая ошибка!<br/> Попробуйте снова.</>});
                formik.setSubmitting(false);
            });
    }

    const validate = (values) => {
        let errors = {};
    
        // name
        if (!values.name) {
            errors.name = 'Обязательное поле';
        }
    
        // phone
        if (!values.phone) {
            errors.phone = 'Обязательное поле';
        }
        if (values.phone && values.phone.length < 10) {
            errors.phone = 'Неверно введён номер телефона'
        }
    
        // email
        if (values.email && !emailRegexp.test(values.email)) {
            errors.email = 'Неверно введён email'
        }
    
        return errors;
    }
    
    const formik = useFormik({
        initialValues,
        onSubmit,
        validate,
    })

    const closeFormHandle = () => {
        setFormStatus({})
    }

    const disabled = formik.isSubmitting || 
        !formik.isValid || 
        (!formik.values.name && !formik.values.phone);


    return (
        <>
            <MainWrapper>
                <FormBlockWrap>
                    {formStatus.success || formStatus.error
                        ? <ProductsFormStatus status={formStatus} getBackToForm={() => setFormStatus({})} autoWidth/>
                        : <FormBox onSubmit={formik.handleSubmit} noValidate>
                            <FormFields>
                                <WrapForPhoneAndName>
                                    <InputComponent
                                        inputMargin='0 15px 18px 0'
                                        inputType='name'
                                        placeholder='Имя'
                                        inputWidth='100%'
                                        inputWrapWidth='47.765%'
                                        notFullWidth
                                        name='name'
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        inputerror={formik.errors.name}
                                    />
                                    <InputComponent
                                        inputMargin='0'
                                        name='phone'
                                        inputType='phone'
                                        placeholder='Телефон'
                                        notFullWidth
                                        inputWidth='100%'
                                        inputWrapWidth='47.765%'
                                        isMask={true}
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        inputerror={formik.errors.phone}
                                    />
                                </WrapForPhoneAndName>
                                <InputComponent
                                    inputMargin="0 0 18px"
                                    inputType="email"
                                    inputWidth='100%'
                                    inputWrapWidth='100%'
                                    notFullWidth
                                    placeholder="E-mail"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    inputerror={formik.errors.email}
                                />
                                <ButtonForSend type="submit" disabled={disabled}>Получить материалы</ButtonForSend>
                            </FormFields>
                        </FormBox>
                    }
                </FormBlockWrap>
            </MainWrapper>

            {formStatus.success || formStatus.error
                ? <ProductsFormStatus closeForm={closeFormHandle} status={formStatus} getBackToForm={() => setFormStatus({})} autoWidth/>
                : null
            }
        </>
    )
}
