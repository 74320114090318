import React from 'react'
import styled from 'styled-components'
import CustomMedia from "../../helpers/media";

import sucSvg from '../../assets/images/form/successProductsStatusForm.svg'
import errSvg from '../../assets/images/form/errorProductsStatusForm.svg'

const MainFormStatusBlockWrap = styled.div`
  position: absolute;
  z-index: 10;
  inset: 0;
  width: 100%;
  height: 100%;  
  min-height: 217px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: ${props => props.autoWidth ? 'auto' : '500px'};
  color: #c8c8c8;
  padding: 20px;
  border: ${props => props.withBorder && '0.5px solid rgba(125, 125, 125, 0.5)'};
  border-radius: 20px;
  text-align: center;
  background-color: #fff; 

  ${CustomMedia.lessThan('mobile')`  
    padding: 0;
    min-height: auto;
  `}
`
const StatusImage = styled.img`
  width: 100%;
  height: 100%;
`

const ButtonClose = styled.button`
  background: none;
  border: none;
  outline: none;
  width: 75px;
  height: 75px;
  padding: 0;
  margin: 10px 0 30px;
  cursor: pointer;

  ${CustomMedia.lessThan('mobile')`
      transform: scale(0.5);
      margin: 0;
    `}
`

const P = styled.p`
  font-size: 20px;
  font-weight: 600;
  ${CustomMedia.lessThan('mobile')`
    font-size: 16px;
  `}
`

const PP = styled.p`
  font-size: 16px;
  font-weight: 400;
  ${CustomMedia.lessThan('mobile')`
    font-size: 14px;
`}
`

export const ProductsFormStatus = ({ status, withBorder, autoWidth, closeForm }) => {
    return (
        <MainFormStatusBlockWrap
            withBorder={withBorder}
            autoWidth={autoWidth}
            onClick={closeForm}
        >
            <ButtonClose onClick={closeForm} type={'button'}>
              <StatusImage src={status.success ? sucSvg : errSvg} />
            </ButtonClose>

            <P>{status.success || status.error}</P>
            <PP>{status.success && <>Файл загружен на ваше устройство в&nbsp;папку&nbsp;&quot;Загрузки&quot;. <br /> Специалист свяжется с вами позже и ответит на возникшие вопросы</>}</PP>
        </MainFormStatusBlockWrap>
    )
}
